<template>
  <div class="home_ranking">
    <div class="home_ranking_box">
      <div class="home_ranking_table">
        <a-table
          :loading="rankingData.rankingLoading"
          :columns="rankingData.columns"
          :data-source="rankingData.data"
          :rowKey="(record, index) => index"
          :pagination="false"
        >
          <template slot="name" slot-scope="text, record">
            <div class="new_title" @click="toDetail(record)">
              <div class="new_title_ic">
                <img
                  src="@/assets/images/homeImg/new_b.png"
                  class="qsy_img"
                  alt=""
                  v-if="record.messageCount == 0"
                />
                <img
                  src="@/assets/images/homeImg/new_a.png"
                  class="qsy_img"
                  alt=""
                  v-else
                />
              </div>
              <div class="new_title_name">{{ record.title }}</div>
            </div>
          </template>
          <template slot="createTime" slot-scope="text">
            <span>{{ text }}</span>
          </template>
          <template slot="messageCount" slot-scope="text">
            <span>{{ text === 0 ? "未读" : "已读" }}</span>
          </template>
        </a-table>
        <div class="ranking_page_box" v-if="rankingData.data.length != 0">
          <div
            :class="[
              'ranking_page_first',
              currentNumFirst ? '' : 'noCursor',
              rankingData.rankingLoading ? 'btn_disabled' : '',
            ]"
            @click="firstBtn"
          >
            首页
          </div>
          <div class="ranking_page_center">
            <a-pagination
              v-model="rankingData.pagination.current"
              :total="rankingData.pagination.total"
              :pageSize="rankingData.pagination.pageSize"
              @change="changeRankPage"
              :disabled="rankingData.rankingLoading"
            />
          </div>
          <div
            :class="[
              'ranking_page_end',
              currentNumEnd ? '' : 'noCursor',
              rankingData.rankingLoading ? 'btn_disabled' : '',
            ]"
            @click="endBtn"
          >
            尾页
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "通知名称",
    dataIndex: "name",
    key: "name",
    align: "left",
    scopedSlots: { customRender: "name" },
    width: 500,
  },
  {
    title: "通知时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "left",
    scopedSlots: { customRender: "createTime" },
    width: 500,
  },
  {
    title: "状态",
    dataIndex: "messageCount",
    key: "messageCount",
    align: "center",
    scopedSlots: { customRender: "messageCount" },
  },
];

import { getList, newsRecord } from "@/api/news";
export default {
  data() {
    return {
      data: [],
      count: 0,
      rankingData: {
        columns: columns,
        data: [],
        rankingLoading: false,
        userData: {},
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
        },
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getData();
  },
  computed: {
    currentNumFirst() {
      if (this.rankingData.pagination.current !== 1) {
        return true;
      } else {
        return false;
      }
    },
    currentNumEnd() {
      let num =
        this.rankingData.pagination.total /
          this.rankingData.pagination.pageSize -
        this.rankingData.pagination.current;
      if (num > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getData() {
      //获取消息列表
      this.rankingData.rankingLoading = true;
      getList({
        pageNo: this.rankingData.pagination.current,
        pageSize: this.rankingData.pagination.pageSize,
      })
        .then((res) => {
          this.rankingData.data = res.result.records;
          this.rankingData.pagination.total = res.result.total;
        })
        .finally(() => {
          this.rankingData.rankingLoading = false;
        });
    },
    // 添加消息阅读记录
    setNewsRecord(id) {
      newsRecord({
        messageId: id,
      }).then(() => {
        this.getData();
      });
    },
    changeRankPage(page, pageSize) {
      // 点击上下页或者其他页的时候
      this.rankingData.pagination.current = page;
      this.rankingData.pagination.pageSize = pageSize;
      this.getData();
    },
    firstBtn() {
      if (this.rankingData.pagination.current == 1) {
        return;
      }
      this.rankingData.pagination.current = 1;
      this.getData();
    },
    endBtn() {
      if (
        Math.ceil(
          this.rankingData.pagination.total /
            this.rankingData.pagination.pageSize
        ) == this.rankingData.pagination.current
      ) {
        return;
      }
      this.rankingData.pagination.current = Math.ceil(
        this.rankingData.pagination.total / this.rankingData.pagination.pageSize
      );
      this.getData();
    },
    toDetail(record) {
      if (record.messageCount === 0) {
        this.setNewsRecord(record.id);
      }
      if (record.forwardType === "VIDEO") {
        this.$router.push({
          name: "gardenDetails",
          query: {
            id: record.taskId,
            type: "MESSAGE_COURSE_TASK",
          },
        });
      } else if (record.forwardType === "LIVE") {
        this.$router.push({
          name: "live",
          query: {
            id: record.forwardContentId,
          },
        });
      } else {
        this.$router.push({
          name: "newsDetails",
          query: {
            id: record.id,
          },
        });
      }
    },
  },
};
</script>

<style lang = "scss" scoped>
.home_ranking {
  width: 1200px;
  margin: 48px auto 192px;
  .home_ranking_box {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 24px;
    background: #ffffff;
    .ranking_page_box {
      margin: 49px auto 18px;
      display: flex;
      justify-content: center;
      .ranking_page_first {
        width: 50px;
        height: 31px;
        border: 1px solid #bfbfbf;
        text-align: center;
        line-height: 29px;
        font-size: 16px;
        font-weight: 400;
        color: #878787;
        border-radius: 31px;
        margin-right: 9px;
        cursor: pointer;
      }
      .ranking_page_end {
        width: 50px;
        height: 31px;
        border: 1px solid #bfbfbf;
        text-align: center;
        line-height: 29px;
        font-size: 16px;
        font-weight: 400;
        color: #878787;
        border-radius: 31px;
        margin-left: 9px;
        cursor: pointer;
      }
      .ranking_page_first:hover,
      .ranking_page_end:hover {
        border: 1px solid #5db4ff;
        color: #ffffff;
        background: #5db4ff;
      }
      .noCursor {
        cursor: not-allowed !important;
      }
      .noCursor:hover {
        border: 1px solid #bfbfbf;
        color: #878787;
        background: #ffffff;
      }
      .btn_disabled {
        background: #f5f5f5;
        border-color: #d9d9d9;
        color: #878787;
      }
    }
    .new_title{
      display: flex;
      align-items: center;
    }
    .new_title_ic{
      width: 9px;
      height: 9px;
      margin-right: 6px;
    }
  }
}

.qsy_img {
  width: 100%;
  height: 100%;
  display: block;
}

::v-deep .ant-table-thead > tr > th {
  border-bottom: none;
  color: #989898;
  padding: 0 49px;
}
::v-deep .ant-table-thead {
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  color: #989898;
}
::v-deep .ant-table-tbody > tr > td {
  color: #989898;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  padding: 0 49px;
}
::v-deep .ant-table-tbody {
  min-height: 700px;
}
::v-deep .ant-table-tbody > tr {
  height: 70px;
}
::v-deep .ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #def0ff;
}
::v-deep .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #def0ff !important;
}

::v-deep .ant-pagination-prev,
.ant-pagination-next {
  width: 31px;
  height: 31px;
  margin-right: 10px;
}
::v-deep .ant-pagination-prev .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf;
  color: #878787;
  font-size: 13px;
}
::v-deep .ant-pagination-prev:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #5db4ff;
  color: #ffffff;
  background: #5db4ff;
}
::v-deep .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf;
  color: #878787;
  font-size: 13px;
}
::v-deep .ant-pagination-next:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #5db4ff;
  color: #ffffff;
  font-size: 13px;
  background: #5db4ff;
}
::v-deep .ant-pagination-item {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  line-height: 29px;
}
::v-deep .ant-pagination-item a {
  padding: 0;
}
::v-deep .ant-pagination-item-active {
  border: 1px solid #5db4ff;
}
::v-deep .ant-pagination-item-active a {
  color: #ffffff;
  background: #5db4ff;
  border-radius: 50%;
}
::v-deep .ant-pagination-item:hover {
  border: 1px solid #5db4ff;
}
::v-deep .ant-pagination-item:hover a {
  color: #ffffff;
  background: #5db4ff;
  border-radius: 50%;
}
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #5db4ff;
}
::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf !important;
  color: #878787 !important;
  background: #ffffff !important;
}
</style>
